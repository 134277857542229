import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, IResultResponse } from '../../core/models';
import { UtilsService } from '../utils/utils.service';
import { map } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../stores/reducers';
import { ERoleUser } from '../../pages/user-role-create/user.role';
@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private store: Store<State>,
  ) { }

  createUserRole(
    body: {
      departmentId: any;
      oktaRoleId: any;
      name: any;
      detail: any;
      isSuperAdmin: boolean;
      roles: string[];
      status: any;
      active: any;
    }
  ) {
    return this.http
      .post<any>('roles', body)
  }

  deleteUserRole(id : number) {
    return this.http
    .delete<any>(`roles/${id}`)
  }
  
  updateUserRole(id : number , body: { name: string; email: string;}) {
    return this.http
    .put<any>(`roles/${id}`, body)
  }

  getUserRoleById(id : number) {
    return this.http
    .get<IApiResponse<any[]>>(`roles/${id}`)
  }

  fetchUserRoles(name: string, departmentId: number, status: any, page: number) {

    let url = 'roles/search/criteria';
    let queryParams = [];

    if (name !== "" && name !== null) {
      queryParams.push(`name=${name}`);
    }

    if (departmentId !== undefined  && departmentId !== 0) {
      queryParams.push(`departmentId=${departmentId}`);
    }

    if (status !== undefined && status !== null && status !== '') {
      queryParams.push(`status=${status}`);
    }

    if (page !== undefined && page !== null) {
      queryParams.push(`page=${page}`);
    }

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }
    return this.http
      .get<IApiResponse<IResultResponse<any[]>>>(url)
      .pipe(
        map((res) => this.utilsService.handleResponse(res)),
        map((data) => {
          if (data) {
            const result = data.result;
            const pagination = data.pagination;
            if (result) {
              return { result, pagination };
            }
          }
          return null;
        })
      );
  }

  bulkCreateUserRole(payloads: any[]){
    return this.http
      .post<any>('roles/bulk', payloads)
  }

  fetchUserRolesAccess() {
    return this.http
    .post<IApiResponse>(`roles/access`, {})
  }

  verifyPermission(access: ERoleUser[]) {
    return this.store.select('roles').pipe(
      map(perm => {
        if(perm.isSuperUser === true) {
          return true;
        }
        else {
          return access.every(role => perm.permissions?.includes(role));
        }
      })
    );
  }

}
