import * as bcryptjs from 'bcryptjs';
import * as CryptoJS from 'crypto-js';

export class BcryptHelper {
  public static hash(key: string) {
    return bcryptjs.hashSync(key, 10);
  }

  public static compareHash(key: string, hash: string) {
    return bcryptjs.compareSync(key, hash);
  }

  public static verifyHash(hash: string) {
    return BcryptHelper.hash(hash);
  }

  public static encryption(key: string, text: string) {

    return CryptoJS.AES.encrypt(text, key).toString();
  }

  public static decryption(key: string, textEncryption: string) {
    return CryptoJS.AES.decrypt(textEncryption, key).toString(CryptoJS.enc.Utf8);
  }
}
